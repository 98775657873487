
<template>
  <div>
    <van-icon v-if="isShowTop" name="arrow-up" class="top-btn" @click="scrollTop" />
  </div>

</template>

<script>
  import { Icon } from 'vant';
  export default {
    name: '',
    components: {
      [Icon.name]: Icon,
    },
    data() {
      return {
        isShowTop: false,
      }
    },
    created() {

    },
    mounted () {
      window.addEventListener('scroll', this.scroll)
    },
    destroyed () {
      window.removeEventListener('scroll', this.scroll);
    },
    methods: {
      // 滑动到顶部
      scrollTop() {
        this.$emit('scrollTop')
      },
      // 获取滚动位置
      scroll() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        this.isShowTop = scrollTop > 300;
      },
    },
  }
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .top-btn {
    position: fixed;
    right: 18px;
    bottom: 20px;
    z-index: 10;
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 22px;
    background-color: #fff;
    border-radius: 100%;
    color: #666;
    text-align: center;
    cursor: pointer;
  }
  
}

// PC端
@media screen and (min-width: 1024px) {
  .top-btn {
    position: fixed;
    right: 5px;
    bottom: 5px;
    z-index: 10;
    width: 15px;
    height: 15px;
    line-height: 14px;
    font-size: 10px;
    background-color: #fff;
    border-radius: 100%;
    color: #666;
    text-align: center;
    cursor: pointer;
  }
}

</style>
